<template>
  <div class="content">
    <div>
      <img class="header" src="@/assets/imgs/openwx/header.png" alt="" />
    </div>
    <div class="cardwarp">
      <div class="card card-1">
        <div class="prod">
          <div class="prod-img">
            <img :src="prodDetail.pic" class="prod-img-detail" alt="" />
          </div>
          <div class="prod-detail">
            <div class="prod-title">
              {{ prodDetail.prodName }}
            </div>
            <!-- <div class="prod-desc">科目一综合素质</div> -->
            <div>
              <span class="price">¥{{ prodDetail.price }}</span>
              <span class="old-price">¥{{ prodDetail.oriPrice }}</span>
            </div>
          </div>
        </div>

        <!-- 微信内打开 -->
        <div v-if="isWeixin && !isDesktop">
          <wx-open-launch-weapp
            id="launch-btn"
            username="gh_7e9819af5925"
            :path="path"
            :env-version="envVersion"
            @launch="handleLaunchFn"
            @error="handleErrorFn"
            class="launchBtn"
            style="height: 44px"
          >
            <!--  -->
            <script v-is="'script'" type="text/wxtag-template">
              <style>.btn { width: 100%;
                            height: 100%;
                              background: #e65b3e;
                               text-align: center;border-radius: 8px;
                                line-height: 44px;
                                color: white;
                                margin: 16px 0 8px 0;
                                font-size: 18px;font-weight: bold; }</style>
              <div class="btn">查看课程</div>
            </script>
          </wx-open-launch-weapp>
        </div>
        <!-- 微信外打开 -->
        <div v-else class="btn" @click="openurl">查看课程</div>

        <div class="desc">若点击按钮无法跳转，可在微信内打开链接哦</div>
      </div>

      <div class="card card-2">
        <span class="card-title">关注公众号获取更多学习资讯</span>
        <span class="card-title">免费课程和题库</span>
        <img class="qrcode" src="@/assets/imgs/openwx/qrcode.png" alt="" />
        <span class="desc">微信内长按识别二维码</span>
      </div>
    </div>

    <shadow-stop></shadow-stop>
  </div>
</template>

<script>
var wx = require("weixin-js-sdk");
import { Toast } from "vant";
const WX_DEBUG = false;
const axios = require("axios");
// import { APPID, USERNAME } from "@/utils/config.js";
import { getWxSign, getDetail, getUrlScheme } from "@/api/test/index";
import { wxMinpogramEnvVersion } from "@/utils/utils.js";
import shadowStop from "@/components/shadowStop";

export default {
  components: {
    shadowStop,
  },
  data() {
    return {
      infor: {},
      urlScheme: "",
      // username: USERNAME,
      prodDetail: {}, // 商品详情页
      prodid: "",
      isWeixin: false, //微信内
      isDesktop: false, //pc端
      isMobile: false, //移动端
      isIOS: false, //ios设备
      dingding: false, //是不是钉钉内
      isQuark: false, //夸克浏览器
      goodsMasterId: "",
    };
  },
  computed: {
    width() {
      return document.body.clientWidth - 90 + "px";
    },
    path() {
      return `pages/kkWebView/index?path=/course/${this.goodsMasterId}`;
    },
    //合法值为：正式版release、开发版develop、体验版trial
    envVersion() {
      return wxMinpogramEnvVersion();
    },
  },
  mounted() {
    this.prodid = this.$route.query.id || "";
    this.initEnv();
    this.getKukeCloudInfo();
    this.getDetail();

    wx.ready(function () {
      var btn = document.getElementById("launch-btn");
      btn.addEventListener("launch", function () {});
      let title = "查看课程";
      let desc = "更多好课 等你查收";
      let imgUrl = "https://oss.kuke99.com/img/book/share/logo.png";
      //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
      wx.updateTimelineShareData({
        title: title, // 分享标题
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {},
      });
      wx.updateAppMessageShareData({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        success: function () {},
      });
    });

    wx.error(function () {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
  },
  methods: {
    initEnv() {
      let ua = navigator.userAgent.toLowerCase();
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      let dingding = ua.match(/DingTalk/i) == "dingtalk";
      var isWXWork = ua.match(/wxwork/i) == "wxwork";
      var isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      var isMobile = false;
      var isDesktop = false;
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
        )
      ) {
        isMobile = true;
      } else {
        isDesktop = true;
      }
      this.isQuark = u.indexOf(" Quark") > 0;
      this.isIOS = isIOS;
      this.isWeixin = isWeixin;
      this.isDesktop = isDesktop;
      this.isMobile = isMobile;
      this.dingding = dingding;
    },

    // 微信外链打开
    openurl() {
      if (this.isDesktop) {
        Toast({
          message: "请在手机端微信客户端内打开本链接",
          className: "toastWidth",
        });
        return;
      }

      if ((this.isIOS && this.dingding) || this.isQuark) {
        Toast("请在微信客户端内打开本链接");
        return;
      }
      if (this.urlScheme) {
        location.href = this.urlScheme;
      } else {
        this.getUrlScheme(true);
      }
    },
    // 微信外获取Scheme
    /**
     * 是否跳转
     */
    async getUrlScheme(isJump = false) {
      const params = {
        path: `pages/kkWebView/index?path=/course/${this.goodsMasterId}`,
      };
      const res = await getUrlScheme(params);
      if (res) {
        this.urlScheme = res.openLink;
        if (isJump && this.urlScheme) {
          location.href = this.urlScheme;
        }
      }
    },

    // 获取库课云对应的信息
    getKukeCloudInfo() {
      axios
        .post(
          `${process.env.VUE_APP_BOOK_TRANSFER_API}/kukecoregoods/bookDataMigration/oldConvertNew`,
          {
            idType: "2",
            oldId: `${this.prodid}`,
          }
        )
        .then((res) => {
          if (res && res.data && res.data.code === "10000") {
            let data = res.data.data;
            if (data && data.goodsMasterId) {
              this.goodsMasterId = data.goodsMasterId || "";
            }
          }
          this.initOpenWeChatEnv();
        })
        .catch(() => {
          this.initOpenWeChatEnv();
        });
    },

    // 初始化微信环境信息
    initOpenWeChatEnv() {
      if (this.isWeixin) {
        // 微信内
        this.getSign();
      } else {
        // 微信外
        this.getUrlScheme();
      }
    },

    async getDetail() {
      const res = await getDetail(this.prodid);
      if (res) {
        this.prodDetail = res;
      }
    },

    async getSign() {
      const res = await getWxSign({
        url: encodeURIComponent(location.href.split("#")[0]),
      });
      if (res) {
        this.infor = res;
        this.initWeChat();
      }
    },
    // 注入config
    initWeChat() {
      wx.config({
        debug: WX_DEBUG,
        appId: this.infor.appId,
        nonceStr: this.infor.nonceStr, // 必填，生成签名的随机串
        signature: this.infor.signature, // 必填，签名
        timestamp: this.infor.timestamp, // 必填，生成签名的时间戳
        jsApiList: [
          "chooseImage",
          "updateTimelineShareData",
          "updateAppMessageShareData",
          "updateAppMessageShareData",
          "hideMenuItems",
        ],
        openTagList: ["wx-open-launch-weapp"], // 微信打开小程序开放标签
      });
    },
    handleLaunchFn() {},
    handleErrorFn() {},
  },
};
</script>

<style scoped>
.content {
  background: #fff1f0;
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
}
.header {
  width: 750px;
  height: 671px;
}
.cardwarp {
  width: 750px;
  height: auto;
  position: relative;
  align-self: center;
  top: -80px;
}
.card {
  margin: 0 32px 24px 32px;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-items: center; */
}

.card-1 {
  background: url("../../assets/imgs/openwx/card1.png");
  background-size: 100% 100%;
  padding: 48px 60px;
}

.card-2 {
  padding-top: 96px;
  background: url("../../assets/imgs/openwx/card2.png");
  background-size: 100% 100%;
  padding-bottom: 52px;
}

.qrcode {
  margin: 32px auto;
  height: 236px;
  width: 236px;
}

.card-title {
  font-size: 28px;
  font-weight: 500;
  color: #434343;
  line-height: 42px;
}

.desc {
  font-size: 22px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
}

.prod {
  display: flex;
  flex-direction: row;
}
.prod-img {
  width: 172px;
  height: 172px;
}

.prod-img-detail {
  width: 100%;
  height: 100%;
}
.prod-detail {
  width: 578px;
  flex: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.prod-title {
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  line-height: 42px;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  margin-bottom: auto;
}
.prod-desc {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 12px 0 20px 0;
}

.price {
  font-size: 36px;
  font-weight: 600;
  color: #e65b3e;
  line-height: 36px;
}

.old-price {
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  text-decoration: line-through;
  margin-left: 20px;
}
.launchBtn {
  width: 570px;
}
.btn {
  width: 570px;
  height: 88px;
  background: #e65b3e;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  line-height: 88px;
  color: white;
  margin: 32px 0 16px 0;
  font-size: 36px;
  font-weight: bold;
}
</style>
<style>
.toastWidth {
  width: auto;
}
</style>
